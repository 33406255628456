<template>
  <section id="final-users">
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("finalUsers.title") }}</h1>
        <h5 class="text-primary">
          {{ $t("finalUsers.subtitle") }}
        </h5>
      </b-col>
      <b-col
        md="3"
        class="text-right"
        align-self="end"
      >
        <b-link
          :to="{
            name: 'final-users-wizard'
          }"
        >
          <b-button variant="primary">
            <span class="text-nowrap">{{ $t("administrators.add") }}</span>
          </b-button>
        </b-link>
      </b-col>
    </b-row>
    <b-card>
      <table-header
        :total="totalUsers"
        :per-page.sync="perPage"
        :search-query.sync="searchQuery"
        :call-method="'allVusers'"
        @export="exportTable"
      />
      <b-table
        ref="refUserListTable"
        :items="fetchedUsers"
        :busy="isBusy"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        :empty-text="$t('emptyText')"
        responsive
        primary-key="id"
      >
        <template #table-busy>
          <div
            v-if="!exporting"
            class="text-center text-danger my-2"
          >
            <b-spinner class="align-middle mr-1" />
            <strong>{{ $t('load') }}...</strong>
          </div>
          <div
            v-else
            class="text-center my-2"
          >
            <div class="text-center">
              <feather-icon
                icon="ClockIcon"
                size="30"
              />
              <p id="cancel-label">
                {{ $t('exporting') }}
              </p>
              <ICountUp
                :delay="delay"
                :end-val="nExport"
                :options="options"
              />
              {{ $t('of') }}
              <ICountUp
                :delay="delay"
                :end-val="totalCount"
                :options="options"
              />
              {{ $t('usuarios') }}
            </div>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(username)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.node.firstName)"
                variant="light-success"
                :to="{
                  name: 'final-users-edit',
                  params: { id: data.item.node.id }
                }"
              />
            </template>
            <b-link
              :to="{
                name: 'final-users-edit',
                params: { id: data.item.node.id }
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.node.firstName }} {{ data.item.node.lastName }}
            </b-link>

            <b-link
              :to="{
                name: 'final-users-edit',
                params: { id: data.item.node.id }
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              <small class="text-muted">@{{ cleanUsername(data.item.node.username) }}</small>
            </b-link>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(email)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="MailIcon"
              size="18"
              class="mr-50"
            />
            <span class="align-text-top">{{ data.item.node.email }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(isActive)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.node.isActive)}`
            "
            class="text-capitalize"
          >
            <feather-icon
              :icon="data.item.node.isActive ? 'CheckIcon' : 'XIcon'"
              size="18"
              class="mr-50"
            />
            {{
              data.item.node.isActive
                ? $t("dataGeneric.active")
                : $t("dataGeneric.inactive")
            }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              dropleft
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="17"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click.prevent="toEditFinalUser(data.item.node.id)">
                <div class="d-flex">
                  <span
                    class="ml-1"
                  >
                    <i
                      class="mr-1 fa fa-user-pen"
                      size="18"
                    />
                    {{ $t('dataGeneric.edit') }}
                  </span>
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="deactivateUser(data.item.node.id)">
                <div
                  class="ml-1 d-flex text-warning"
                >
                  <i
                    class="mr-1 fa fa-user-xmark text-warning"
                    size="18"
                  />
                  {{ $t('dataGeneric.deactivate') }}
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="deleteUser(data.item.node.id)">
                <div
                  class="ml-1 d-flex text-danger"
                >
                  <i
                    class="mr-1 fa fa-user-slash text-danger"
                    size="18"
                  />
                  {{ $t('dataGeneric.delete') }}
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <table-footer
        v-model="currentPage"
        :from="dataMeta.from"
        :to="dataMeta.to"
        :total="totalUsers"
        :per-page="perPage"
      />
    </b-card>
  </section>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BLink,
  BSpinner,
  BMedia,
  BButton,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import axios from '@axios'
import { avatarText } from '@core/utils/filter'

import {
  messageError, cleanUsername, showToast, isEmpty,
} from '@/store/functions'
import xlsExport from 'xlsexport'
import { getUserData } from '@/auth/utils'
import ICountUp from 'vue-countup-v2'
import TableFooter from '../../components/table/TableFooter.vue'
import TableHeader from '../../components/table/TableHeaderPers.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BLink,
    ICountUp,
    BMedia,
    BSpinner,
    BAvatar,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    // My components
    TableFooter,
    TableHeader,
  },
  data() {
    return {
      userData: getUserData(),
      isBusy: false,
      exporting: false,
      dataToExport: [],
      nExport: 0,
      totalCount: 0,
      delay: 1000,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },

      // Table Handlers
      tableColumns: [
        { key: 'username', sortable: true, label: this.$t('administrators.user') },
        { key: 'email', sortable: true },
        { key: 'isActive', sortable: true, label: this.$t('code.status') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },
      ],
      perPage: 10,
      totalUsers: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      fetchedUsers: null,
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refUserListTable ? this.$refs.refUserListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      }
    },
  },
  watch: {
    searchQuery() {
      this.fetchUsers()
    },
    perPage() {
      this.fetchUsers()
    },
    currentPage() {
      this.fetchUsers()
    },
  },
  mounted() {
    this.fetchUsers()
  },
  methods: {
    avatarText,
    cleanUsername,
    deleteUser(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteUserMutation(id)
            .then(response => {
              const { found, deletedId } = response.data.data.deleteVuser
              if (found && deletedId) {
                this.fetchUsers()
                showToast(this.$t('success'), 1, this)
              }
            })
            .catch(() => {
              showToast(this.$t('error'), 2, this)
            })
        }
      })
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      const zeroWidthSpace = '\u200C'
      return `${zeroWidthSpace}${day}/${month}/${year}`
    },
    fetchUsers() {
      const data = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        status: this.statusFilter,
      }
      this.fetchUsersQuery(data)
        .then(response => {
          const { edges, totalCount } = response.data.data.allVusers
          this.fetchedUsers = edges
          this.totalUsers = totalCount
        })
        .catch(() => {
          showToast(this.$t('errorList'), 2, this)
        })
    },
    exportData(cursor = '', typeExport, dates = []) {
      const query = `
        {
          allVusers (client:"${this.userData.profile.client.id}",first:300, 
          after:"${cursor}",          
          ${!isEmpty(dates) ? `startDate: "${dates[0]}", endDate: "${dates[1]}"` : ''}){
            pageInfo {
              endCursor
              hasNextPage
            }
            totalCount
            edges {
              node {
                id
                username
                email
                firstName
                lastName
                isLegalWarning
                isAcceptAds
                isActive
                dateJoined
                lastLogin
                nif
                phone
              }
            }
          }
        }
      `
      axios
        .post('', {
          query,
        })
        .then(response => {
          messageError(response, this)
          const info = response.data.data.allVusers
          const page = info.pageInfo
          const { edges, totalCount } = info
          const nodes = edges.map(item => item.node)
          this.dataToExport = [...this.dataToExport, ...nodes]
          this.nExport = this.dataToExport.length
          this.totalCount = totalCount
          if (page.hasNextPage) {
            this.exportData(page.endCursor, typeExport, dates)
          } else {
            const dataTable = this.dataToExport.map(item => ({
              [this.$t('ID')]: item.id,
              [this.$t('User')]: item.username.replace(`${this.userData.profile.client.name}_`, ''),
              [this.$t('name')]: `${item.firstName} ${item.lastName}`,
              [this.$t('Email')]: item.email,
              [this.$t('acept')]: item.isLegalWarning,
              [this.$t('promoRecep')]: item.isAcceptAds,
              [this.$t('code.status')]: item.isActive ? this.$t('dataGeneric.active') : this.$t('dataGeneric.inactive'),
              [this.$t('dateRegistration')]: this.formatDate(item.dateJoined),
              'NIF/CIF/NIE': item.nif,
              [this.$t('finalUsers.Phone')]: item.phone,
            }))
            const f = new Date()
            const m = f.getMonth() + 1
            const name = `${this.$t('fileNameUserRegistered') + f.getFullYear() + (m < 10 ? (`0${m}`) : `${m}`)}${f.getDate()}`

            // eslint-disable-next-line new-cap
            const xls = new xlsExport(dataTable, this.$t('Users'))
            if (typeExport === 'XLS') xls.exportToXLS(`${name}.xls`)
            else xls.exportToCSV(`${name}.csv`)

            this.exporting = !this.exporting
            this.isBusy = !this.isBusy
          }
        }).catch(err => {
          console.log(err)
          showToast(this.$t('subtitles.translationError'), 0, this)
        })
    },
    exportTable(data) {
      this.dataToExport = []
      this.exporting = !this.exporting
      this.isBusy = !this.isBusy
      this.nExport = 0
      const dates = []
      let typeExport = ''
      if (Array.isArray(data)) {
        const [type, startDate, endDate] = data
        typeExport = type
        dates.push(startDate)
        dates.push(endDate)
      } else {
        typeExport = data
      }
      this.exportData('', typeExport, dates)
    },
    deactivateUser(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.confirmDeactive'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deactivateUserMutation(id)
        }
      })
    },
    deactivateUserMutation(id) {
      const timeStamp = Math.floor(Date.now() / 1000)
      axios
        .post('', {
          query: `
              mutation($subgroup: ID, $userAddress: [ID], $pin: Int){
                updateVuser(id:"${id}", input: {
                  username: "deleted_user_${timeStamp}",
                  firstName: "Deleted User",
                  lastName: "",
                  email: "",
                  isActive: false,
                  phone: "",
                  address: "",
                  city: "",
                  province: "",
                  postalCode: "",
                  country: "",
                  description: "",
                  nif: "",
                  pin: $pin,
                  subgroup: $subgroup,
                  userAddress: $userAddress,
                }) {
                  vUser{
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    dateJoined
                    lastLogin
                    gender
                    phone
                    address
                    city
                    province
                    postalCode
                    country
                    nif
                    pin
                    description
                    confirmedEmail
                    isLegalWarning
                    isAcceptAds
                    subgroup{
                      id
                      name
                    }
                  }
                }
              }
            `,
          variables: {
            subgroup: null,
            userAddress: [],
            pin: null,
          },
        })
        .then(response => {
          messageError(response)
          showToast(this.$t('success'), 1, this)
          this.fetchUsers()
        })
        .catch(error => {
          messageError(error)
          showToast(this.$t('error'), 2, this)
        })
    },
    deleteUserMutation(id) {
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              mutation{
                deleteVuser(id:"${id}") {
                  found
                  deletedId
                }
              }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsersQuery(queryParams) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            // params: queryParams
            query: `
              {
                allVusers(
                    first: ${queryParams.perPage},
                    offset: ${queryParams.perPage * (queryParams.page - 1)},
                    username: "${queryParams.q}",
                    client:"${userData.profile.client.id}",

                    orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                    ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                  ) {
                  totalCount
                  edgeCount
                  edges {
                    node {
                      id
                      username
                      firstName
                      lastName
                      email
                      isActive
                      isLegalWarning
                      isAcceptAds
                    }
                  }
                }
              }
            `,
          })
          .then(response => {
            const allVusers = response
            const searchName = response.data.data.allVusers.edges

            axios
              .post('', {
                // params: queryParams
                query: `
                              {
                                allVusers(
                                    first: ${queryParams.perPage},
                                    offset: ${queryParams.perPage * (queryParams.page - 1)},
                                    email: "${queryParams.q}",
                   client:"${userData.profile.client.id}",

                                    orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                                    ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                                  ) {
                                  totalCount
                                  edgeCount
                                  edges {
                                    node {
                                      id
                                      username
                                      firstName
                                      lastName
                                      email
                                      isActive
                                      isLegalWarning
                                      isAcceptAds
                                    }
                                  }
                                }
                              }
                          `,
              }).then(result => {
                const searchMail = result.data.data.allVusers.edges
                let nuevo = [...searchName, ...searchMail]

                const hash = {}
                nuevo = nuevo.filter(current => {
                  const exists = !hash[current.node.id]
                  hash[current.node.id] = true
                  return exists
                })
                allVusers.data.data.allVusers.edges = nuevo
                resolve(allVusers)
              }).catch(err => {
                reject(err)
              })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    resolveUserStatusVariant(status) {
      if (status === true) return 'success'
      if (status === false) return 'secondary'
      return 'secondary'
    },
    toEditFinalUser(id) {
      this.$router.push({
        name: 'final-users-edit',
        params: { id },
      })
    },
  },
}
</script>
